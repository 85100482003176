import Vue from 'vue'
import Vuex from 'vuex'
import product from "@/store/product";
import user from "@/store/user";
import waybill from "@/store/waybill";
import unit from "@/store/unit";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    product,
    user,
    waybill,
    unit
  }
})
