// import api from "@plugins/api"
import api from "../plugins/api.js"

const state = {
    list: [],
    document_item: [],

    count: 0,
    page_count: 0,
    record_per_page: 0,
}


const getters = {}

const actions = {
    // eslint-disable-next-line no-unused-vars
    list({commit}, payload) {
        return api.get(`waybill?page=${payload.page}`).then((res) => {
            commit('list', res.data)
        })
    },
    // eslint-disable-next-line no-unused-vars
    document_item({commit}, payload) {
        return api.get('product/').then((res) => {
            commit('document_item', res.data)
        })
    },
    // eslint-disable-next-line no-unused-vars
    create({commit}, payload){
        return api.post('waybill/', payload)
        //     .then((res) => {
        //     commit('list', res.data)
        // })
    },
    // eslint-disable-next-line no-unused-vars
    update({commit}, payload){
        return api.put(`waybill/${payload.pk}/`, payload)
    },
      // eslint-disable-next-line no-unused-vars
    delete({commit}, pk){
        return api.delete(`waybill/${pk}/`).then((res) => {
            commit('list', res.data)
        })
    },
    // eslint-disable-next-line no-unused-vars
    get_print({commit}, pk){
        return api.get(`waybill/${pk}/get_print/`)
    }

}

const mutations = {
    list(state, res) {
        // console.log(res)
        state.list = res.list
        state.count = res.count
        state.page_count = res.page_count
        state.record_per_page = res.record_per_page
    },
    document_item(state, res) {
        state.document_item = res
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
