<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
    >

      <v-list dense>

        <v-list-item  @click="goTo('dashboard')">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Ana Sayfa
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item @click="goTo('waybill')">
          <v-list-item-action>
            <v-icon>mdi-truck-check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              İrsaliye
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  v-if="user.is_superuser" @click="goTo('product')">
          <v-list-item-action>
            <v-icon>mdi-corn</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Ürünler
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item  v-if="user.is_superuser" @click="goTo('unit')">
          <v-list-item-action>
            <v-icon>mdi-sack</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Birimler
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user.is_superuser" @click="goTo('user')">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Kullanıcı Yönetimi
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Oturumu Kapat</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list>

      <div  v-if="this.$vuetify.theme.dark === true" style='position: absolute;bottom:15px;left:15px;font-family:"Roboto", sans-serif;opacity:0.3;'>
        <div style="float:left;">
          <span class="orange--text text--lighten-2">{{ user.username }}</span> <span  v-if="user.is_superuser">[Super User]</span><br />
          {{ user.first_name }} {{ user.last_name }}<br />
          <span style="font-size: x-small" v-if="user.app_info">Kozlu WayBill
            {{user.app_info.stage_version}}
            V{{user.app_info.app_version}}
            B{{user.app_info.build_version}}
           {{user.app_info.stage_scope}}</span>
        </div>
      </div>

       <div  v-if="this.$vuetify.theme.dark === false" style='position: absolute;bottom:15px;left:15px;font-family:"Roboto", sans-serif;opacity:1;'>
        <div style="float:left;">
          <span class="black--text text--lighten-2">{{ user.username }}</span> <span  v-if="user.is_superuser">[Super User]</span><br />
          {{ user.first_name }} {{ user.last_name }}<br />
          <span style="font-size: x-small" v-if="user.app_info">Kozlu WayBill
            {{user.app_info.stage_version}}
            V{{user.app_info.app_version}}
            B{{user.app_info.build_version}}
           {{user.app_info.stage_scope}}</span>
        </div>
      </div>

    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>


      <img src="../assets/kozlulogo.png" height="40" />
      <!--<v-toolbar-title>KOZLU İrsaliye</v-toolbar-title>-->

      <v-icon v-if="isFullScreen===false" style="position: absolute;right:30px;" @click="requestFullScreen()">
        mdi-fullscreen
      </v-icon>
      <v-icon v-if="isFullScreen" style="position: absolute;right:30px;" @click="exitFullScreen()">mdi-fullscreen-exit
      </v-icon>

    </v-app-bar>


    <v-main>

      <v-container>
        <v-row align="center" justify="center">
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>


    </v-main>
  </v-app>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Dashboard",
  components: {},
  data: () => ({
    drawer: null,
    isFullScreen: false,
  }),
  created() {
    this.$vuetify.theme.dark = false
  },
  computed: {
    ...mapState({
      user: state => state.user.obj
    })
  },
  watch: {
    user: function (val) {
      if (!(val.pk > 0)) {
        this.goTo("login")
      }
    }
  },
  mounted() {
    this.$store.dispatch('user/info')
  },
  methods: {
    requestFullScreen() {
      this.isFullScreen = true
      var requestMethod = document.body.requestFullScreen || document.body.webkitRequestFullScreen || document.body.mozRequestFullScreen || document.body.msRequestFullScreen
      if (requestMethod) {
        requestMethod.call(document.body)
      }

      document.activeElement.blur();

    },

    exitFullScreen() {
      this.isFullScreen = false

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }

      document.activeElement.blur()

    },
    goTo(routeName) {
      if (this.$router.currentRoute.name !== routeName) {
        this.$router.push({
          "name": routeName
        })
      }
    },
    logout() {
      this.$store.dispatch("user/logout")
    }
  }
}
</script>
