// import api from "@plugins/api"
import api from "../plugins/api.js"

const state = {
    list: []
}


const getters = {}

const actions = {
    // eslint-disable-next-line no-unused-vars
    list({commit}, payload) {
        return api.get('unit/').then((res) => {
            commit('list', res.data)
        })
    },
    // eslint-disable-next-line no-unused-vars
    create({commit}, payload){
        return api.post('unit/', payload)
    },
    // eslint-disable-next-line no-unused-vars
    update({commit}, payload){
        return api.put(`unit/${payload.pk}/`, payload)
    },
      // eslint-disable-next-line no-unused-vars
    delete({commit}, pk){
        return api.delete(`unit/${pk}/`)
    },

}

const mutations = {
    list(state, res) {
        state.list = res
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
