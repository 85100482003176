<template>
  <div>

<!--    <div v-for="(item, index) in list" :key="index">-->
<!--      {{item.pk}}-->
<!--    </div>-->
    <v-data-table
        :headers="headers"
        :items="list"
        :options.sync="options"
        :server-items-length="count"
        :page.sync="page"
        :items-per-page="record_per_page"
        :search="search_text"
        :loading="loading"
        item-key="pk"
        class="elevation-1"
        no-data-text="İrsaliye verisi yok"
        no-results-text="İrsaliye bulunamadı"
        :footer-props="{'items-per-page-options': []}"
    >
      <template v-slot:item.document_number="{ item }">
        <span v-if="item.document_number">{{ item.document_number }}</span>
        <span v-if="!item.document_number">
          <v-icon>
            mdi-timer-sand
          </v-icon>
        </span>

      </template>
      <template v-slot:top>


        <v-dialog v-model="document_item_dialog" max-width="600px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-data-table
                        item-key="pk"
                        show-select
                        class="elevation-1"
                        :single-select="true"
                        v-model="currentProductItem"
                        dense
                        :headers="document_item_headers"
                        :items="document_item"
                        :loading="document_item_loading"
                        :footer-props="{
                          'items-per-page-options': [10, 20, 30, 40, 50]
                        }"
                        :items-per-page="30"
                        no-data-text="Ürün verisi yok"
                        no-results-text="Ürün bulunamadı"
                        hide-default-footer
                    >


                      <template v-slot:top>

                        <v-row>

                          <!--                                                    <v-icon @click="document_item_dialog=false"-->
                          <!--                                                            style="right:15px;position:absolute;cursor: pointer;"-->
                          <!--                                                            right>mdi-close-->
                          <!--                                                    </v-icon>-->
                          <v-col cols="9" style="margin-top: 15px;">
                            <v-text-field @input="document_item_TypeList()"
                                          ref="ref_document_item_search_text"
                                          append-icon="mdi-magnify"
                                          style="margin-left:15px;"
                                          v-model="document_item_search_text"
                                          label="Ürün Ara"></v-text-field>
                          </v-col>
                          <v-col cols="3" style="margin-top: 15px;">
                            <v-text-field style="margin-right:15px;" label="Miktar" type="number"
                                          @keypress="quantityKeyDown($event)"
                                          v-model="current_product.quantity"></v-text-field>
                          </v-col>

                        </v-row>

                      </template>


                    </v-data-table>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="margin-top:-30px;" @click="document_item_dialog=false" small>
                <v-icon small>
                  mdi-close
                </v-icon>
                Vazgeç
              </v-btn>
              <v-btn style="margin-top:-30px;" @click="addDocumentItem()" small>
                <v-icon small>
                  mdi-plus
                </v-icon>
                Ürün Seç
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--delete dialog-->
        <v-dialog
            v-model="delete_confirm_dialog"
            max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Silme Onayı</v-card-title>

            <v-card-text>
              <span style="font-weight: bold;">
                {{ delete_confirm_dialog_item.code }}
                {{ delete_confirm_dialog_item.name }}
              </span>
              <div>İrsaliyesini silmek istediğinize emin misiniz?</div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  @click="delete_confirm_dialog = false"
              >
                Vazgeç
              </v-btn>

              <v-btn
                  color="red darken-1"
                  text
                  @click="deleteItem(delete_confirm_dialog_item)"
              >
                Sil
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--document item delete dialog-->
        <v-dialog
            v-model="document_item_delete_dialog"
            max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Silme Onayı</v-card-title>

            <v-card-text>
              <span style="font-weight: bold;">
                {{ document_item_delete_item_product.code }}
                {{ document_item_delete_item_product.name }}
              </span>
              <div>Ürününü irsaliyeden çıkarmak istediğinize emin misiniz?</div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  @click="document_item_delete_dialog = false"
              >
                Vazgeç
              </v-btn>

              <v-btn
                  color="red darken-1"
                  text
                  @click="delete_document_item()"
              >
                Sil
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <!--edit dialog-->
        <v-dialog v-model="dialog" max-width="1024px" persistent>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-row style="">

                <v-form
                    style="width:100%;"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                  <v-container>

                    <div style="position:absolute;right:15px;top:0px;">
                      <v-col id="div_qr">
                        <canvas id="canvas"></canvas>
                      </v-col>
                    </div>
                    <v-row v-if="editedIndex!==-1">
                      <v-col cols="2">
                        <v-text-field

                            v-model="editedItem.document_number"
                            :counter="15"
                            :readonly="true"
                            label="Belge No"
                            required
                        ></v-text-field>

                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                            v-model="editedItem.create_date"
                            :readonly="true"
                            label="Kayıt Tarihi"
                            required
                        ></v-text-field>
                      </v-col>

                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-text-field :readonly="readonly" v-model="editedItem.plate"
                                      :rules="waybillPlateRules"
                                      @change="upperPlate()" @keydown="upperPlate()"
                                      @keydown.space.prevent :counter="8"
                                      label="Araç Plaka">

                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field :counter="30" :rules="waybillFirstNameRules"
                                      @change="upperDriverFirstName()"
                                      @keydown="upperDriverFirstName()"
                                      :readonly="readonly" v-model="editedItem.driver_firstname"
                                      label="Sürücü Ad"></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field :rules="waybillLastNameRules"
                                      @change="upperDriverLastName()"
                                      @keydown="upperDriverLastName()" :counter="30"
                                      :readonly="readonly" v-model="editedItem.driver_lastname"
                                      label="Sürücü Soyad"></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field @keypress="onlyNumber" maxlength = "11"
                                      v-model="editedItem.driver_identity" :readonly="readonly"
                                      :rules="waybillTcRules" :counter="11" label="Sürücü T.C.">

                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :rules="descriptionRules" :counter="70"
                                      :readonly="readonly" v-model="editedItem.description"
                                      @change="cntrolDesc()"
                                      @keydown="cntrolDesc()"
                                      label="Açıklama">
                        </v-text-field>
                      </v-col>
                      <!--                      <v-col cols="2">-->
                      <!--                        <v-text-field @keypress="onlyNumber" v-model="editedItem.driver_bornyear" :readonly="readonly"-->
                      <!--                                      :rules="waybillBornYearRules" :counter="4" label="Sürücü Doğum Yılı">-->

                      <!--                        </v-text-field>-->
                      <!--                      </v-col>-->
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-data-table
                            :headers="document_headers"
                            :items="products_windex"
                            item-key="pk"
                            class="elevation-1"
                            hide-default-footer
                            disable-pagination
                            no-data-text="Bu belgeye henüz ürün eklenmedi"
                        >
                          <!--                          <template v-slot:item.quantity="{item}">-->
                          <!--                            <v-text-field v-model="newQuantityValue" @keypress="onlyNumber" @keydown="updateQuantity(item)"  style="width:80px;" >-->
                          <!--                            </v-text-field>-->
                          <!--&lt;!&ndash;                            {{item}}&ndash;&gt;-->

                          <!--                          </template>-->
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                                v-if="!readonly"
                                small
                                @click="show_document_item_delete(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>

                    <v-row v-if="!readonly">
                      <v-col>
                        <v-btn @click="show_document_item_dialog()"
                               class="green--text text--darken-3" small>
                          <v-icon small style="margin-right:5px;">
                            mdi-plus
                          </v-icon>
                          Ürün Ekle
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-container>

                </v-form>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <span style="font-size:x-small;margin-left:15px;opacity:0.6">{{
                  editedItem.uuid
                }}@{{ editedItem.user_text }}

              </span>
              <v-spacer></v-spacer>
              <v-btn small v-if="readonly" color="orange darken-2" text @click="close">
                <v-icon style="margin-right:5px;">mdi-close</v-icon>
                Kapat
              </v-btn>
              <v-btn small v-if="!readonly" color="orange darken-2" text @click="close">
                <v-icon style="margin-right:5px;">mdi-close</v-icon>
                Vazgeç
              </v-btn>
              <v-btn :disabled="disable_save_button" small v-if="!readonly" color="green darken-3" text
                     @click="save">
                <v-icon style="margin-right:5px;">mdi-content-save</v-icon>
                Kaydet
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn @click="editItem(defaultItem)" style="margin-top:20px;margin-left:15px;" small>
          <v-icon small style="margin-right:5px;">
            mdi-plus
          </v-icon>
          Yeni İrsaliye
        </v-btn>
        <v-btn hidden small style="margin-top:20px;margin-left:15px;" @click="refreshList">
          <v-icon small style="margin-right:5px;">
            mdi-refresh
          </v-icon>
          Yenile
        </v-btn>
        <v-row>
          <v-col class="col-sm-8 col-md-6 col-lg-3">
            <v-text-field @change="waybillList()" append-icon="mdi-magnify" style="padding-top:20px;"
                          v-model="search_text"
                          label="İrsaliye Ara"
                          class="mx-4"></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.actions="{ item }">

        <v-icon
            v-if="item.status===2"
            medium
            class="mr-2"
            @click="printItemShow(item)"
        >
          mdi-printer-eye
        </v-icon>
        <v-icon
            v-if="item.status===2"
            medium
            class="mr-2"
            @click="printItem(item)"
        >
          mdi-cloud-print
        </v-icon>
        <v-icon
            medium
            class="mr-2"
            @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
            v-if="user.is_superuser"
            medium
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            v-if="user.is_superuser"
            medium
            @click="deleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>

    <v-snackbar :color="snackbar.color" v-model="snackbar.visible" timeout="3000">
      <div style="text-align: center">{{ snackbar.text }}</div>
    </v-snackbar>

  </div>
</template>

<script>
import {mapState} from "vuex";
import * as QRCode from "qrcode";

export default {
  name: "WayBill",
  computed: {
    formTitle() {
      let result = ''
      if (this.editedIndex === -1 && !this.readonly) {
        result = 'Yeni İrsaliye'
      }
      if (this.editedIndex !== -1 && !this.readonly) {
        result = 'İrsaliye Düzenle'
      }
      if (this.readonly) {
        result = 'İrsaliye Önizle'
      }
      return result
    },
    products_windex: function () {
      return this.editedItem.products.map(
          (items, index) => ({
            ...items,
            index: index + 1
          }))
    },
    ...mapState({
      document_item: state => state.waybill.document_item,
      list: state => state.waybill.list,
      user: state => state.user.obj,
      count: state => state.waybill.count,
      page_count: state => state.waybill.page_count,
      record_per_page: state => state.waybill.record_per_page,
    })
  },
  data() {
    return {
      newQuantityValue: 0,
      document_headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'index',
        },
        {
          text: 'Ürün Kodu',
          align: 'start',
          sortable: false,
          value: 'product.code',
        },
        {
          text: 'Ürün Adı',
          align: 'start',
          sortable: false,
          value: 'product.name',
        },
        {
          text: 'Miktar',
          align: 'start',
          sortable: false,
          value: 'quantity',
        },
        {
          text: 'Birim',
          align: 'start',
          sortable: false,
          value: 'product.unit.name',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        },
      ],
      snackbar: {
        visible: false,
        text: "",
        color: ""
      },
      timer: '',
      dialog: false,
      readonly: false,
      disable_save_button: false,
      document_item_loading: false,
      document_item_dialog: false,
      delete_confirm_dialog: false,
      document_item_delete_dialog: false,
      document_item_delete_item: {},
      document_item_delete_item_product: {},
      delete_confirm_dialog_item: {},
      document_item_search_text: '',
      current_product: {
        product: {},
        quantity: 1
      },
      editedIndex: -1,
      editedItem: {
        pk: 0,
        document_number: '',
        description: '',
        plate: '',
        driver_name: '',
        driver_firstname: '',
        driver_lastname: '',
        driver_bornyear: 0,
        driver_identity: '',
        create_date: '',
        uuid: '',
        user_text: '',
        products: [],
      },
      defaultItem: {
        pk: 0,
        document_number: '',
        description: '',
        plate: '',
        driver_firstname: '',
        driver_lastname: '',
        driver_bornyear: 0,
        driver_identity: '',
        create_date: '',
        uuid: '',
        user_text: '',
        products: [],

      },
      currentProductItem: [],
      search_text: "",
      page: 1,
      totalOrders: 0,
      orders: [],
      loading: true,
      options: {},
      document_item_headers: [
        {
          text: 'Ürün Kodu',
          align: 'start',
          sortable: false,
          value: 'code'
        },
        {
          text: 'Ürün Adı',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Birim',
          align: 'start',
          sortable: false,
          value: 'unit.name'
        },

      ],
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'pk',
        },
        {
          text: 'Belge No',
          sortable: false,
          value: 'document_number',
        },
        {
          text: 'Plaka',
          sortable: false,
          value: 'plate',
        },
        {
          text: 'Sürücü Ad',
          sortable: false,
          value: 'driver_firstname',
        },
        {
          text: 'Sürücü Soyad',
          sortable: false,
          value: 'driver_lastname',
        },
        {
          text: 'Kayıt Yapan',
          value: 'user_text',
          sortable: false,
        },
        {
          text: 'Kayıt Tarihi',
          value: 'create_date',
          sortable: false,
        },
        {
          text: 'Durum',
          value: 'status_text',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        },


      ],
      form: {}
      ,
      waybillCodeRules: [
        v => !!v || 'İrsaliye kodu gereklidir',
        v => (v && v.length <= 30) || 'İrsaliye kodu 30 karakterden kısa olmalıdır',
      ],
      waybillNameRules: [
        v => !!v || 'İrsaliye adı gereklidir',
        v => (v && v.length <= 30) || 'İrsaliye adı 30 karakterden kısa olmalıdır',
      ],
      waybillFirstNameRules: [
        v => !!v || 'Sürücü Adı gereklidir',
        v => (v && v.length <= 30) || 'Sürücü Adı 30 karakterden kısa olmalıdır',
      ],
      waybillLastNameRules: [
        v => !!v || 'Sürücü Soyadı gereklidir',
        v => (v && v.length <= 30) || 'Sürücü Soyadı 30 karakterden kısa olmalıdır',
      ],
      descriptionRules: [
        v => !!v || 'Açıklama gereklidir',
        v => (v && v.length <= 70) || 'Açıklama 70 karakterden kısa olmalıdır',
      ],

      waybillUnitRules: [
        v => !!v || 'Birim seçimi gereklidir',
      ],
      waybillPlateRules: [
        v => !!v || 'Plaka gereklidir',
        v => (v && v.length <= 8) || 'Plaka alanı en fazla 8 karakter olabilir',
        v => (v && v.length >= 6) || 'Plaka alanı en az 6 karakter olabilir',
      ],
      waybillTcRules: [
        v => !!v || 'T.C. no gereklidir',
        v => (v && v.length === 11) || 'T.C. no 11 karakter olmalıdır',
      ],
      waybillBornYearRules: [
        v => !!v || 'Doğum yılı gereklidir',
        v => (v && v.toString().length === 4) || 'Doğum yılı 4 karakter olmalıdır',
        v => (v && v >= 1900) || 'Geçerli bir doğum yılı girin',
      ],
      email: '',
      valid: true,

    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    page() {
      this.loading = true
      this.$store.dispatch("waybill/list", {
        "page": this.page,
        "search": this.search_text
      }).then(() => {
        this.loading = false
      })
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    updateQuantity(item) {
      // console.log(item)
      this.$forceUpdate()
      this.editedItem.products[0].quantity = 9999

      // console.log(this.editedItem.products)
      this.$forceUpdate()

    },
    refreshList() {
      this.loading = true
      this.$store.dispatch("waybill/list", {
        "page": 1,
        "search": this.search_text
      }).then(() => {
        this.loading = false
      })
    },
    printItemShow(item) {
      this.$store.dispatch("waybill/get_print", item.pk).then((res) => {
        var mywindow = window.open('', 'PRINT', 'height=800,width=800')
        mywindow.document.write(res.data.detail)
        mywindow.document.title = ""
        mywindow.document.close()
        mywindow.focus()
        // mywindow.print()
        // mywindow.close()
        return true;
      })

    },
    printItem(item) {
      this.$store.dispatch("waybill/get_print", item.pk).then((res) => {

        var mywindow = window.open('', 'PRINT', 'height=400,width=600')
        mywindow.document.write(res.data.detail)
        mywindow.document.title = ""
        mywindow.document.close()
        mywindow.focus()
        mywindow.print()
        mywindow.close()
        return true;
      })

    },
    quantityKeyDown(evt) {
      // console.log(evt)
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((
              (charCode > 31 && (charCode < 48 || charCode > 57))
              && charCode !== 46
          )
          || evt.target.value.toString().trim().length >= 5
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    delete_document_item() {
      this.editedItem.products.splice(this.document_item_delete_item.index - 1, 1)
      this.document_item_delete_dialog = false
    },

    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    control(){
        let check = false
        for (let i = 0; i < this.editedItem.products.length; i++) {
         let stokkodu =this.editedItem.products[i].product.code
          let miktar = this.editedItem.products[i].quantity
          if(stokkodu === this.currentProductItem[0].code && miktar === this.current_product.quantity){
          check = true
         }
        }
        //console.log(check)
        return check
    },


    addDocumentItem() {
      if(this.control()) {
        this.snackbar = {
          color: "red",
          text: "Aynı malzeme aynı tonajda olamaz!",
          visible: true
        }
        return
      }

      if (this.currentProductItem.length === 0) {
        this.snackbar = {
          color: "red",
          text: "Malzeme seçilmelidir!",
          visible: true
        }
        return
      }
      // console.log(this.currentProductItem)
      if (!(this.current_product.quantity > 0)) {
        this.snackbar = {
          color: "red",
          text: "Miktar alanı boş geçilemez",
          visible: true
        }
        return
      }




      this.editedItem.products.push({
        product: this.currentProductItem[0],
        quantity: this.current_product.quantity
      })
      this.document_item_dialog = false
    },
    // eslint-disable-next-line no-unused-vars
    selectDocumentItem(item) {

      // if (!this.current_product.quantity > 0) {
      //     this.current_product.quantity = 1
      // }
      // console.log("selectDocumentItem:")
      // console.log(item)
      this.currentProductItem = item

      // this.addDocumentItem()


    },
    show_document_item_dialog() {
      this.currentProductItem = []
      this.current_product.quantity = null
      this.document_item_dialog = true
      this.document_item_TypeList()
    },
    show_document_item_delete(item) {
      this.document_item_delete_item = item
      this.document_item_delete_item_product = item.product
      this.document_item_delete_dialog = true
    },
    document_item_TypeList() {
      this.document_item_loading = true
      this.$store.dispatch("waybill/document_item", {
        "search": this.document_item_search_text
      }).then(() => {
        this.document_item_loading = false
      })

    },
    upperPlate() {
      if (this.editedItem.plate) {
        this.editedItem.plate = this.editedItem.plate.toUpperCase().replace("'","").replace(">","").replace("<","")
      }
    },
    cntrolDesc() {
      if (this.editedItem.description) {
        this.editedItem.description = this.editedItem.description.replace("'","").replace(">","").replace("<","")
      }
    },
    upperDriverFirstName() {
      if (this.editedItem.driver_firstname) {
        this.editedItem.driver_firstname = this.editedItem.driver_firstname.toUpperCase().replace("'","").replace(">","").replace("<","")
      }
    },
    upperDriverLastName() {
      if (this.editedItem.driver_lastname) {
        this.editedItem.driver_lastname = this.editedItem.driver_lastname.toUpperCase().replace("'","").replace(">","").replace("<","")
      }
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    waybillList() {
      this.loading = true
      this.$store.dispatch("waybill/list", {
        "page": 1,
        "search": this.search_text
      }).then(() => {
        this.loading = false
      })
    },
    deleteDialog(item) {
      this.delete_confirm_dialog = true
      this.delete_confirm_dialog_item = item
    },
    showItem(item) {
      this.dialog = true
      this.readonly = true
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.$nextTick(() => {
        QRCode.toCanvas(document.getElementById('canvas'), this.editedItem.uuid, {width: 75}, function () {
        })
      })
    },
    editItem(item) {

      if (item.pk === 0) {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.products = []
        this.editedIndex = -1

        this.$nextTick(() => {

          this.$refs.form.reset()
          this.editedItem.uuid = this.uuidv4()
          this.editedItem.user_text = this.user.username

          this.editedItem.create_date = new Date().toLocaleDateString()

          QRCode.toCanvas(document.getElementById('canvas'), this.editedItem.uuid, {width: 75}, function () {
          })

        })

      } else {
        this.editedIndex = this.list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$nextTick(() => {
          QRCode.toCanvas(document.getElementById('canvas'), this.editedItem.uuid, {width: 75}, function () {
          })
        })
      }
      this.dialog = true
      this.readonly = false
    },
    deleteItem(item) {
      this.delete_confirm_dialog = false
      let promise = this.$store.dispatch("waybill/delete", item.pk)
      promise.then((res) => {
        if (res.data.status === "ok") {
          this.snackbar.visible = true
          this.snackbar.text = "Silindi"
          this.snackbar.color = "orange"
          this.loading = true
          this.$store.dispatch("waybill/list", {
            "page": 1,
            "search": this.search_text
          }).then(() => {
            this.loading = false
          })
        }
        if (res.data.status === "error") {
          this.snackbar.visible = true
          this.snackbar.text = res.data.help_text
          this.snackbar.color = "red"
        }
      })

      promise.catch(() => {
        this.snackbar.visible = true
        this.snackbar.text = "Genel hata. Sunucuyla bağlantı koptu."
        this.snackbar.color = "red"
      })

    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {

      // console.log(this.list[0].products)
      // console.log(this.editedItem.products)

      if (this.editedItem.products.length === 0) {
        this.snackbar.visible = true
        this.snackbar.text = "Malzeme eklenmeden irsaliye oluşturulamaz"
        this.snackbar.color = "red"
        this.disable_save_button = false
        return
      }

      let validate = this.$refs.form.validate()
      if (!validate)
        return
      this.disable_save_button = true
      let promise
      if (this.editedIndex > -1) {
        promise = this.$store.dispatch("waybill/update", this.editedItem)
      } else {
        promise = this.$store.dispatch("waybill/create", this.editedItem)
      }
      promise.then((res) => {
        this.disable_save_button = false
        if (res.data.status === "ok") {
          this.snackbar.visible = true
          this.snackbar.text = "İrsaliye Kaydedildi"
          this.snackbar.color = "green"

          this.loading = true
          this.$store.dispatch("waybill/list", {
            "page": 1,
            "search": this.search_text
          }).then(() => {
            this.loading = false
          })
          this.close()

        }
        if (res.data.status === "error") {
          this.snackbar.visible = true
          this.snackbar.text = res.data.help_text
          this.snackbar.color = "red"
        }
      })
      promise.catch(() => {
        this.disable_save_button = false
        this.snackbar.visible = true
        this.snackbar.text = "Genel hata. Sunucuyla bağlantı koptu."
        this.snackbar.color = "red"
      })
    },
    refreshInterval() {
      this.loading = true
      this.$store.dispatch("waybill/list", {
        "page": this.page,
        "search": this.search_text
      }).then(() => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.loading = true
    this.$store.dispatch("waybill/list", {
      "page": 1,
      "search": this.search_text
    }).then(() => {
      this.loading = false
    })
    this.timer = setInterval(this.refreshInterval, 30000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
