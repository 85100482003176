import Vue from 'vue'
import VueRouter from 'vue-router'
import Product from '../views/Product.vue'
import WayBill from '../views/WayBill.vue'
import User from '../views/User.vue'
import Login from '../views/Login.vue'
import Site from '../views/Site.vue'
import Unit from '../views/Unit.vue'

Vue.use(VueRouter)

const routes = [
      {
    path: '/',
    name: 'dashboard',
    component: Site,
    children: [
        {
            path: '/waybill',
            name: 'waybill',
            component: WayBill
        },
        {
            path: '/product',
            name: 'product',
            component: Product
        },
        {
            path: '/user',
            name: 'user',
            component: User
        },
        {
            path: '/unit',
            name: 'unit',
            component: Unit
        }
    ]
  },
    {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router
