<template>
  <div>

    <v-data-table
        :headers="headers"
        :items="list"
        :options.sync="options"
        :server-items-length="count"
        :page.sync="page"
        :items-per-page="record_per_page"
        :search="search_text"
        :loading="loading"
        item-key="pk"
        class="elevation-1"
        :footer-props="{'items-per-page-options': []}"
    >
      <template v-slot:top>


        <!--delete dialog-->
        <v-dialog
            v-model="delete_confirm_dialog"
            max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Silme Onayı</v-card-title>

            <v-card-text>
              <span style="font-weight: bold;">
                {{ delete_confirm_dialog_item.code }}
                {{ delete_confirm_dialog_item.name }}
              </span>
              <div>Ürününü silmek istediğinize emin misiniz?</div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  @click="delete_confirm_dialog = false"
              >
                Vazgeç
              </v-btn>

              <v-btn
                  color="red darken-1"
                  text
                  @click="deleteItem(delete_confirm_dialog_item)"
              >
                Sil
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--edit dialog-->
        <v-dialog v-model="dialog" max-width="500px">

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-row style="">

                <v-form
                    style="width:100%;"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                  <v-container>

                    <v-row>
                      <v-col cols="7">
                        <v-text-field
                            v-model="editedItem.code"
                            :counter="30"
                            :rules="productCodeRules"
                            :readonly="readonly"
                            label="Ürün Kodu"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-select
                            v-model="editedItem.unit.pk"
                            label="Birim"
                            :readonly="readonly"
                            :items="units"
                            item-text="name"
                            item-value="pk"
                            :rules="productUnitRules"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                 <v-row>
                   <v-col>
                     <v-text-field
                         v-model="editedItem.name"
                         :counter="30"
                         :readonly="readonly"
                         :rules="productNameRules"
                         label="Ürün Adı"
                         required
                     ></v-text-field>
                   </v-col>
                 </v-row>

                  </v-container>

                </v-form>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small v-if="readonly" color="orange darken-2" text @click="close">
                <v-icon style="margin-right:5px;">mdi-close</v-icon>
                Kapat
              </v-btn>
              <v-btn small v-if="!readonly" color="orange darken-2" text @click="close">
                <v-icon style="margin-right:5px;">mdi-close</v-icon>
                Vazgeç
              </v-btn>
              <v-btn small v-if="!readonly" color="green darken-3" text @click="save">
                <v-icon style="margin-right:5px;">mdi-content-save</v-icon>
                Kaydet
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn @click="editItem(defaultItem)" style="margin-top:20px;margin-left:15px;" small>
          <v-icon small style="margin-right:5px;">
            mdi-plus
          </v-icon>
          Yeni Ürün
        </v-btn>
        <v-row>
          <v-col class="col-sm-8 col-md-6 col-lg-3">
            <v-text-field @change="productList()" append-icon="mdi-magnify" style="padding-top:20px;"
                          v-model="search_text"
                          label="Ürün Ara"
                          class="mx-4"></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>

    <v-snackbar :color="snackbar.color" v-model="snackbar.visible" timeout="3000">
      <div style="text-align: center">{{ snackbar.text }}</div>
    </v-snackbar>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Product",
  computed: {
    formTitle() {
      let result = ''
      if (this.editedIndex === -1 && !this.readonly) {
        result = 'Yeni Ürün'
      }
      if (this.editedIndex !== -1 && !this.readonly) {
        result = 'Ürün Düzenle'
      }
      if (this.readonly) {
        result = 'Ürün Önizle'
      }
      return result
    },
    ...mapState({
      list: state => state.product.list,
      units: state => state.unit.list,
      count: state => state.product.count,
      page_count: state => state.product.page_count,
      record_per_page: state => state.product.record_per_page,
    })
  },
  data() {
    return {

      snackbar: {
        visible: false,
        text: "",
        color: ""
      },
      dialog: false,
      readonly: false,
      delete_confirm_dialog: false,
      delete_confirm_dialog_item: {},
      desserts: [],
      editedIndex: -1,
      editedItem: {
        pk: 0,
        code: '',
        name: '',
        unit: {
          pk: 0,
          code: '',
          name: ''
        }
      },
      defaultItem: {
        pk: 0,
        code: '',
        name: '',
        unit: {
          pk: 0,
          code: '',
          name: ''
        }
      },
      search_text: "",
      page: 1,
      totalOrders: 0,
      orders: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'Ürün Kodu',
          align: 'start',
          sortable: false,
          value: 'code',
        },
        {
          text: 'Ürün Adı',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Birim',
          sortable: false,
          value: 'unit.name',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        },

      ],
      form: {
        code: '',
        name: '',
      }
      ,
      productCodeRules: [
        v => !!v || 'Ürün kodu gereklidir',
        v => (v && v.length <= 30) || 'Ürün kodu 30 karakterden kısa olmalıdır',
      ],
      productNameRules: [
        v => !!v || 'Ürün adı gereklidir',
        v => (v && v.length <= 30) || 'Ürün adı 30 karakterden kısa olmalıdır',
      ],
      productUnitRules: [
        v => !!v || 'Birim seçimi gereklidir',
      ],
      email: '',
      valid: true,

    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    page() {
      this.loading = true
      this.$store.dispatch("product/list", {
        "page": this.page,
        "search": this.search_text
      }).then(() => {
        this.loading = false
      })
    },
  },
  methods: {
    productList() {
      this.loading = true
      this.$store.dispatch("product/list", {
        "page": 1,
        "search": this.search_text
      }).then(() => {
        this.loading = false
      })
    },
    deleteDialog(item) {
      this.delete_confirm_dialog = true
      this.delete_confirm_dialog_item = item
    },
    showItem(item){
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.readonly = true
      document.activeElement.blur()

    },
    editItem(item) {
      if (item.pk === 0) {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      } else {
        this.editedIndex = this.list.indexOf(item)
        this.editedItem = Object.assign({}, item)
      }
      this.dialog = true
      this.readonly = false
      document.activeElement.blur()
    },
    deleteItem(item) {
      this.delete_confirm_dialog = false
      let promise = this.$store.dispatch("product/delete", item.pk)
      promise.then((res) => {
        if (res.data.status === "ok") {
          this.snackbar.visible = true
          this.snackbar.text = "Silindi"
          this.snackbar.color = "orange"
          this.loading = true
          this.$store.dispatch("product/list", {
            "page": 1,
            "search": this.search_text
          }).then(() => {
            this.loading = false
          })
        }
        if (res.data.status === "error") {
          this.snackbar.visible = true
          this.snackbar.text = res.data.help_text
          this.snackbar.color = "red"
        }
      })

      promise.catch(() => {
        this.snackbar.visible = true
        this.snackbar.text = "Genel hata. Sunucuyla bağlantı koptu."
        this.snackbar.color = "red"
      })

    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      let validate = this.$refs.form.validate()
      if (!validate)
        return
      let promise
      if (this.editedIndex > -1) {
        promise = this.$store.dispatch("product/update", this.editedItem)
      } else {
        promise = this.$store.dispatch("product/create", this.editedItem)
      }
      promise.then((res) => {
        if (res.data.status === "ok") {
          this.snackbar.visible = true
          this.snackbar.text = "Kaydedildi"
          this.snackbar.color = "green"

          this.loading = true
          this.$store.dispatch("product/list", {
            "page": 1,
            "search": this.search_text
          }).then(() => {
            this.loading = false
          })
          this.close()

        }
        if (res.data.status === "error") {
          this.snackbar.visible = true
          this.snackbar.text = res.data.help_text
          this.snackbar.color = "red"
        }
      })
      promise.catch(() => {
        this.snackbar.visible = true
        this.snackbar.text = "Genel hata. Sunucuyla bağlantı koptu."
        this.snackbar.color = "red"
      })
    },
  },
  mounted() {
    this.loading = true
    this.$store.dispatch("unit/list")
    this.$store.dispatch("product/list", {
      "page": 1,
      "search": this.search_text
    }).then(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped>

</style>
