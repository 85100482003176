<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  props: {
    source: String,
  },
  components: {},
  beforeCreate() {
    window.document.title = "Kozlu İrsaliye"
  }
}
</script>
