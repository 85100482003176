import api from "@/plugins/api";

const state = {
    obj: {},
    list: [],
    count: 0,
    page_count: 0,
    record_per_page: 0,
}

const getters = {}

const actions = {
    info({commit}) {
        return api.get(`user/info/`)
            .then(obj => {
                commit('set', obj.data)
            })
    },
    logout({commit}) {
        return api.get(`user/logout/`)
            .then(obj => {
                commit('set', obj.data)
            })
    },
    // eslint-disable-next-line no-unused-vars
    login({commit}, payload) {
        return api.post(`user/login/`, payload).then(obj => {
            commit('set', obj.data)
        })
    },
    list({commit}, payload) {
        return api.get(`user/?page=`+payload.page+"&search="+payload.search)
            .then(obj => {
                commit('list', obj.data)
            })
    },
    // eslint-disable-next-line no-unused-vars
    create({commit}, payload){
        return api.post('user/', payload)
    },
    // eslint-disable-next-line no-unused-vars
    update({commit}, payload){
        return api.put(`user/${payload.pk}/`, payload)
    },
    // eslint-disable-next-line no-unused-vars
    delete({commit}, pk) {
        return api.delete(`user/${pk}/`)
    },
    // eslint-disable-next-line no-unused-vars
    reset2fa({commit}, payload){
        return api.get(`user/reset2fa/?user_pk=${payload.pk}`, payload)
    },
}

const mutations = {
    set(state, obj) {
        state.obj = obj
    },
    list(state, res) {
        state.list = res.list
        state.count = res.count
        state.page_count = res.page_count
        state.record_per_page = res.record_per_page
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
