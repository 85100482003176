

<template>
  <v-app id="inspire">



    <v-main>
      <v-container
          fluid
          fill-height
      >
        <v-row
            align="center"
            justify="center"

        >

          <v-col class="col-sm-8 col-md-6 col-lg-4 col-xl-4">
            <center>


            <img src="../assets/kozlugidaimalat.jpg" height="120" />

            </center>
            <br/>
            <v-card class="elevation-12">
              <v-toolbar
                  color=""
                  dark
                  flat
              >

                <v-toolbar-title>

                  <center>  <span>Kullanıcı Girişi</span>
                  </center>

                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-icon size="15" style="margin-left:5px;margin-right:10px;" color="blue">fas fa-plus</v-icon>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                      label="Kullanıcı Adı"
                      v-model="username"
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                  ></v-text-field>

                  <v-text-field
                      @change="login()"
                      id="password"
                      v-model="password"
                      label="Şifre"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="login()" color="green">GİRİŞ</v-btn>
              </v-card-actions>
            </v-card>


          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>


    </v-footer>


    <v-snackbar :color="snackbar.color" v-model="snackbar.visible" timeout="3000">
      <div style="text-align: center">{{ snackbar.text }}</div>
    </v-snackbar>

  </v-app>



</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      snackbar: {
        visible: false,
        text: "",
        color: ""
      },
      username: "",
      password: "",
      secret: ""
    }
  },
  computed:{
    ...mapState({
      user: state => state.user.obj
    })
  },
  created() {
    this.$vuetify.theme.dark = false
  },
  watch:{
    user: function(val){
      if (val.pk > 0)
        window.location = ""
      else
      {
        // console.log("değil")
        this.snackbar.visible = true
        this.snackbar.text = "Yanlış kullanıcı adı veya şifre"
        this.snackbar.color = "red"
      }
    }
  },
  mounted(){
    // if(this.user != null){
    //   this.goTo("dashboard")
    // }
  },
  methods: {
    goTo(routeName) {
      if (this.$router.currentRoute !== routeName) {
        this.$router.push({
          "name": routeName
        })
      }
    },

    login() {
      this.$store.dispatch("user/login", {
        "username": this.username,
        "password": this.password,
        "secret": this.secret
        // eslint-disable-next-line no-unused-vars
      })

      // console.log(promise)



    },

  }
}
</script>

<style scoped>

</style>
